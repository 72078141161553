import { gql, useMutation } from "@apollo/client"
import { css } from "@emotion/core"
import { Container, Paper } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Field, Form, Formik } from "formik"
import { TextField } from "formik-material-ui"
import { Link, navigate } from "gatsby"
import React, { useEffect } from "react"
import uuid from "uuid/v4"
import * as Yup from "yup"
import { useAuth } from "../hooks/useAuth"
import { setAuthToken, setRefreshToken } from "../services/auth"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("No password provided."),
  // .min(8, "Password is too short - should be 8 chars minimum."),
})

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 560,
    width: "100%",
    padding: theme.spacing(4),
    margin: `${theme.spacing(4)}px 0`,
  },
  textFieldFull: {
    width: "100%",
  },
}))

const LOGIN_USER = gql`
  mutation LoginUser($input: LoginInput!) {
    login(input: $input) {
      authToken
      refreshToken
    }
  }
`

const Login = () => {
  const auth = useAuth()

  useEffect(() => {
    if (auth.token) {
      navigate(`/dashboard/`, { replace: true })
    }
  }, [auth.token])

  const classes = useStyles()
  const [
    loginUser,
    { data: loginData, error: loginError, loading: loginLoading },
  ] = useMutation(LOGIN_USER)

  loginError && console.log(loginError)

  return (
    <Layout>
      <Seo title={"Login"} />
      <Container
        maxWidth={"xl"}
        css={css`
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
          margin-top: 32px;
        `}
      >
        <Paper className={classes.root}>
          <Typography
            align={"center"}
            variant={"h3"}
            css={css`
              padding-bottom: 32px;
            `}
          >
            Login
          </Typography>
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={LoginSchema}
            onSubmit={(values, { setSubmitting }) => {
              loginUser({
                variables: {
                  input: {
                    clientMutationId: uuid(),
                    username: values.username,
                    password: values.password,
                  },
                },
              }).then(response => {
                // console.log("Response", response)

                const { login } = response.data

                if (login) {
                  // console.log(login)
                  setAuthToken(login.authToken)
                  auth.setToken(login.authToken)
                  setRefreshToken(login.refreshToken, () =>
                    navigate("/dashboard/")
                  )
                }
              })
              setSubmitting(false)
            }}
          >
            {({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field
                      className={classes.textFieldFull}
                      name="username"
                      type="username"
                      label="Username or Email"
                      component={TextField}
                      variant={"outlined"}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      className={classes.textFieldFull}
                      type="password"
                      name="password"
                      label="Password"
                      component={TextField}
                      variant={"outlined"}
                      required
                    />
                  </Grid>
                </Grid>
                <Button
                  type={"submit"}
                  css={css`
                    margin-top: 32px;
                  `}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  size={"large"}
                  fullWidth
                >
                  Login
                </Button>
              </Form>
            )}
          </Formik>
          {loginError && (
            <Typography
              color={"error"}
              css={css`
                margin-top: 32px;
              `}
            >
              Your credentials seem incorrect. Please try again.
            </Typography>
          )}
          <Typography
            variant={"body2"}
            style={{ textAlign: "center", marginTop: 16 }}
          >
            Don't have an account, yet? Please go to the{" "}
            <Link to={"/signup/"}>Sign Up Page</Link>.
          </Typography>
        </Paper>
      </Container>
    </Layout>
  )
}

export default Login
